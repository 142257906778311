import React from 'react'

const Profile = () => {
  return (
    
    <>
    <div>
        This is your profile page
    </div>

    </>
  )
}

export default Profile